import React from 'react'
import './FullScreenItem.css'

function FullScreenItem() {
    return (
        <div className='fullscreen_container'>
            <h1>this is full screen item</h1>
        </div>
    )
}

export default FullScreenItem
